import React, { Component } from "react";
import MyItem, {MyTitle} from './MyItem';

class Contact extends Component {
  render() {
    return (
      <div
        style={{
          boxShadow: "0 0 10px #cac7c7",
          background: "#ffffff",
          height: '220px'
        }}
      >

        <MyTitle title='連絡先' numberText={3} currentPage={this.props.currentPage}/>
        <MyItem title='電話番号' content={this.props.contact?.buyer?.tel}/>
        <MyItem title='メールアドレス' content={this.props.contact?.buyer?.mailAddr}/>
      </div>
    );
  }
}

export default Contact;
