import axios from 'axios';

export default function ConfirmAPI(API_URL, varToken, BODY){
    return axios({
        method: 'put', 
        url: API_URL,
        headers: {
          Authorization: 'Bearer ' + varToken
        },
        data: BODY
      })
}