import { Form, Input } from "antd";
//import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { MyTitle } from "./MyItem";
import confirmAPI from "../RESTAPI/ConfirmAPI";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;
const Message = (props) => {
  const { infoOrder, currentPage } = props

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const handle = (e) => {
    props.getData(e.target.value);
  };
  const handleMemo = (e) => {
    props.getDataMemo(e.target.value);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  const onFill = () => {
    form.setFieldsValue({
      note: 'Hello world!',
      gender: 'male',
    });
  };

  useEffect(() => {
    if (infoOrder) {
      form.setFieldsValue({
        hanbaitenNumber: infoOrder.hanbaitenNumber,
        hanbaitenMemo: infoOrder.hanbaitenMemo
      });
    }
  }, [form, infoOrder])

  return (
    <div
      style={{
        boxShadow: "0 0 10px #cac7c7",
        background: "#ffffff",
        flex: 1,
      }}
    >
      <MyTitle
        title='注文承認に関する情報'
        numberText={5}
        currentPage={currentPage}
      />
      <Form
        form={form}
        {...layout}
        labelAlign={"left"}
        style={{ margin: "20px", fontSize: "18px" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        
      >
        <Form.Item label={currentPage === 'hat' ? '販売店注番' : '貴社注番'} name="hanbaitenNumber">
          <Input onChange={handle} disabled={infoOrder?.status !== 'pending'}/>
        </Form.Item>
        <Form.Item label='承認メモ' name="hanbaitenMemo">
          <TextArea onChange={handleMemo} disabled={infoOrder?.status !== 'pending'}/>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Message;
