import React, { Component } from "react";
import MyItem, { MyTitle , MyItemMultiLine} from "./MyItem";
import moment from "moment";

class InforOrder extends Component {
  render() {
    console.log(this.props.infoOrder?.updatedAt, "datetime");
    const parseDate = Date.parse(this.props.infoOrder?.createdAt);
    console.log(parseDate, "datetime1");
    const dateparce = moment(parseDate).format("YYYY-MM-DD hh:mm");
    console.log(dateparce, "datetime2");

    return (
      <div
        style={{
          boxShadow: "0 0 10px #cac7c7",
          background: "#ffffff",
          marginBottom: "20px",
        }}
      >
        <MyTitle
          title='注文情報'
          numberText={1}
          currentPage={this.props.currentPage}
        />
        <MyItem
          title='注文番号'
          content={this.props.infoOrder?.id}
          currentPage={this.props.currentPage}
        />
        <MyItem
          title='工事店名'
          content={this.props.infoOrder?.buyer?.nameKanji}
        />
        {this.props.currentPage === "hat" && (
          <MyItem
            title='ご紹介販売店'
            content={this.props.infoOrder?.seller?.nameKanji}
          />
        )}
        <MyItem
          title={'貴社注番'}
          content={this.props.infoOrder?.customerNumber || "（なし）"}
        />
        <MyItemMultiLine
          title='注文メモ'
          content={this.props.infoOrder?.memo || "（なし）"}
        />
        <MyItem
          title='注文日時'
          content={dateparce}
          // content={
          //   (this.props.infoOrder?.createdAt
          //     ? this.props.infoOrder?.createdAt.substring(0, 10)
          //     : "") +
          //   (this.props.infoOrder?.createdAt
          //     ? " " + this.props.infoOrder?.createdAt.substring(11, 16)
          //     : "")
          // }
          // content={moment(this.props.infoOrder?.createdAt).format(
          //   "YYYY/MM/DD hh:mm"
          // )}
        />
      </div>
    );
  }
}

class OneItem extends Component {}

export default InforOrder;
