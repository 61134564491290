import React, { Component, useState } from "react";
import MyItem, { MyTitle } from "./MyItem";
import { Table } from "antd";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    align: "left",
    width: 50,
  },
  {
    title: "商品名",
    dataIndex: "name",
  },
  {
    title: "商品画像",
    dataIndex: "urlImage",
    align: "center",
    render: (theImageURL) => (
      <img
        style={{ width: 80, height: 60 }}
        alt={theImageURL}
        src={theImageURL}
      />
    ),
  },
  {
    align: "center",
    title: "数量",
    dataIndex: "amount",
    width: 60,
  },
  {
    title: "価格",
    dataIndex: "sellingPrice",
  },
];

export default function YourOrder(props) {
  const [hasProductWithPriceUnset, setHasProductWithPriceUnset] = useState(false);

  console.log(props.currentPage, "asdsadsa");
  const data = [];
  let totalPrice = 0;
  for (let i = 0; i < props.yourOrder?.products?.length; i++) {
    if (props.currentPage === "hat") {
      if (props.yourOrder?.products[i].ownerProductType !== "hanbaiten") {
        data.push({
          key: i + 1,
          name: props.yourOrder?.products[i].name,
          urlImage: props.yourOrder?.products[i].urlImage,
          amount: props.yourOrder?.products[i].amount,
          sellingPrice:
            "‎¥ " +
            Number(props.yourOrder?.products[i].sellingPrice).toLocaleString(),
        });
        totalPrice +=
          props.yourOrder?.products[i].amount *
          props.yourOrder?.products[i].sellingPrice;

        if (props.yourOrder?.products[i].sellingPrice ===0 && !hasProductWithPriceUnset) {
          setHasProductWithPriceUnset(true);
        }
      }
    } else {
      data.push({
        key: i + 1,
        name: props.yourOrder?.products[i].name,
        urlImage: props.yourOrder?.products[i].urlImage,
        amount: props.yourOrder?.products[i].amount,
        sellingPrice:
          "‎¥ " +
          Number(props.yourOrder?.products[i].sellingPrice).toLocaleString(),
      });
      totalPrice +=
        props.yourOrder?.products[i].amount *
        props.yourOrder?.products[i].sellingPrice;

      if (props.yourOrder?.products[i].sellingPrice ===0 && !hasProductWithPriceUnset) {
        setHasProductWithPriceUnset(true);
      }
    }
  }

  return (
    <div
      style={{
        boxShadow: "0 0 10px #cac7c7",
        background: "#ffffff",
        marginBottom: "20px",
        minHeight: '60%'
      }}
    >
      <MyTitle
        title='注文内容'
        numberText={4}
        currentPage={props.currentPage}
      />
      <div className='Item'>
        <Table
          scroll={{ y: 300 }}
          pagination={false}
          columns={columns}
          dataSource={data}
        />
      </div>

      {hasProductWithPriceUnset &&<p style={{ textAlign: 'center', fontSize: 15, paddingTop: 5, color: 'red'}}>
        ※価格が0円の商品は都度見積の商品です。
      </p>}

      <div
        style={{
          display: "flex",
          fontSize: "20px",
          justifyContent: "center",
        }}
      >
        <p>お買い上げ金額:</p>
        <div
          style={{
            display: "flex",
            fontSize: "20px",
            fontWeight: "bold",
            marginLeft: "10px",
            color: props.currentPage === "hat" ? "#092ea9" : "black",
          }}
        >
          <p>¥ {totalPrice.toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
}
