import React, { Component } from "react";

class MyItem extends Component {
  render() {
    return (
      <div style={{ marginTop: 10, display: " flex" }}>
        <div className='oderinginfor'>
          <div className='ordering'>
            <h1 className='orderingText'>{this.props.title}</h1>
          </div>
          <div className='wrapper'>
            <p className='name'>{this.props.content}</p>
          </div>
        </div>
      </div>
    );
  }
}

export class MyItemMultiLine extends Component {
  render() {
    return (
      <div style={{ marginTop: 10, display: " flex" }}>
        <div className='oderinginfor'>
          <div className='ordering'>
            <h1 className='orderingText'>{this.props.title}</h1>
          </div>
          <div className='wrapper'>
            <div className='name'>
              {this.props.content && this.props.content.split('\n').map((item,key) => {
                  return <div key={key}>{item}</div>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyItem;

export class MyTitle extends Component {
  render() {
    return (
      <div style={{ display: " flex", borderBottom: "1px #d3d3d3 solid" }}>
          <div className={this.props.currentPage === 'hat' ? 'numberTitleHat' : 'numberTitle'}>
            <p className='text-Title'>{this.props.numberText}</p>
          </div>
          <div>
            <h1 className={this.props.currentPage === 'hat' ? 'titleStyleHat' : 'titleStyle'}>{this.props.title}</h1>
          </div>
        </div>
    );
  }
}
