import React, { useEffect } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import 'antd/dist/antd.css';
import PostAPI from '../RESTAPI/PostAPi';

let values = [];

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
  },
};
const Login = (props) => {
  const [state, setState] = React.useState(props.setToken);
  const [loginFailed, setLoginFailed] = React.useState(false);
  
  const API = props.API

  const API_HANBAITEN_LOGIN = `${API}/auth/login`;
  const API_HAT_LOGIN = `${API}/auth/hat/confirm/login`;


  const onFinish = (values) => {
    setLoginFailed(false);
    PostAPI(
      props.currentPage === 'hat' ? API_HAT_LOGIN : API_HANBAITEN_LOGIN,
      props.currentPage === 'hat'
        ? {
            shainCd: values.username,
            password: values.password,
          }
        : {
            username: values.username,
            password: values.password,
          }
    )
      .then((res) => {
        if (`${res.status}` === '200' || `${res.status}` === '201') {
          localStorage.setItem('access_token', res.data.access_token);
          setState(false);
          window.location.reload(true);
        } else {
          setLoginFailed(true);
        }
      })
      .catch((err) => {
        setLoginFailed(true);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = (e) => {
    setState(false);
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onFinish(values);
    }
  };

  const handleCancel = (e) => {
    setState(false);
  };

  useEffect(() => {
    setState(props.setToken);
  }, [props.setToken]);

  return (
    <div>
      <Modal
        title={
          props.currentPage === 'hat'
            ? 'ログイン（勤怠システムのアカウント）'
            : 'ログイン'
        }
        visible={state}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          {...layout}
          name='basic'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='ユーザID'
            name='username'
            rules={[
              {
                required: true,
                message: 'ユーザIDを入力して下さい',
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label='パスワード'
            name='password'
            rules={[
              {
                required: true,
                message: 'パスワードを入力して下さい',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          {loginFailed && (
            <div
              style={{
                color: 'red',
                fontSize: 13,
                height: 'auto',
                with: '100%',
                textAlign: 'right',
              }}
            >
              ログイン失敗しました。IDとパスワードをもう一度確認して下さい。
            </div>
          )}
          <div class="CenterButton">
            <Button
              style={{
                textAlign: "center",  
                backgroundColor:
                  props.currentPage === 'hat' ? '#092ea9' : '#141925',
                color: 'white',
              }}
              htmlType='submit'
              onKeyDown={_handleKeyDown}
            >
              ログインする
            </Button>
              </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
