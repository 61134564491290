import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import "./App.css";
import InforOrder from "../InforOrder";
import AddressShip from "../AddressShip";
import Contact from "../Contact";
import { notification, Row, Col } from "antd";
import YourOrder from "../YourOrder";
import Login from "../Login";
import ConfirmAPI from "../../RESTAPI/ConfirmAPI";
import { Button, Modal } from "antd";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import axios from "axios";

import * as qs from "query-string";
import Message from "../Message";

import { API_CHUBU, API_TOKYO, API_KYUSHU, API_CHUGOKU }  from '../../RESTAPI/endpoint'
const PAGE_HANBAITEN = "hanbaiten";
const PAGE_HAT = "hat";

const Medital = (props) => {
  let CURRENT_PAGE = PAGE_HANBAITEN;
  const parameters = qs.parse(window.location.search);
  
  const API = parameters.type 
    ? `https://${parameters.type}.mirai-chi.com`
    : `https://chubu.mirai-chi.com` // PRODUCTION

  // const API = parameters.type 
  //   ? `https://${parameters.type}-stag.mirai-chi.com`
  //   : `https://chubu-stag.mirai-chi.com` // TEST
  
  if (parameters && parameters.page === "hat") {
    CURRENT_PAGE = PAGE_HAT;
  }

  let idOrder = "";
  if (parameters && parameters.orderid) {
    idOrder = "/" + parameters.orderid;
  }

  let API_HANBAITEN_ORDERINFO = `${API}/orders` + idOrder;
  console.log(API_HANBAITEN_ORDERINFO, "API_HANBAITEN_ORDERINFO");
  let API_HANBAITEN_APROVE = `${API}/orders/approve`;
  let API_HANBAITEN_CANCEL = `${API}/orders/cancel`;

  let API_HAT_CONFIRM = `${API}/orders/confirm`;

  var idOrders = idOrder.substring(1);
  const varToken = localStorage.getItem("access_token");
  const [order, setOrder] = useState([]);
  const [loginToken, setLoginToken] = useState(!varToken);
  const [isInserting, setIsInserting] = useState(false);

  const onConfirm = () => {
    setIsInserting(true);
    console.log(varToken);
    ConfirmAPI(
      CURRENT_PAGE === PAGE_HAT ? API_HAT_CONFIRM : API_HANBAITEN_APROVE,
      varToken,
      {
        id: idOrders,
        hanbaitenNumber: messageData,
        hanbaitenMemo: messageDataMemo,
      }
    )
      .then((res) => {
        notification["success"]({
          message: "注文が承認されました。",
          description: "",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((err) => {
        if (err && `${err.statusCode}` === "401") {
          setLoginToken(true);
        }
        setIsInserting(false);
        notification["error"]({
          className: "ChangeSizeNotifica",
          message: "エラーが発生しました。処理は完了していません。",
          description: "",
        });
      });
  };
  const onClickCancel = () => {
    setIsInserting(true);
    ConfirmAPI(API_HANBAITEN_CANCEL, varToken, {
      id: idOrders,
      hanbaitenNumber: messageData,
      hanbaitenMemo: messageDataMemo,
    })
      .then((res) => {
        notification["success"]({
          message: "注文が取り消されました",
          description: "",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((err) => {
        if (err && `${err.statusCode}` === "401") {
          setLoginToken(true);
        }
        setIsInserting(false);
        notification["error"]({
          message: "エラーが発生しました。処理は完了していません。",
          description: "",
        });
      });
  };
  useEffect(() => {
    axios({
      method: "get",
      url: API_HANBAITEN_ORDERINFO,
      headers: {
        Authorization: "Bearer " + varToken,
      },
    })
      .then((res) => {
        console.log(res);
        if (!res || !res.data) {
          setLoginToken(true);
          setOrder(null);
        } else setOrder(res.data);
        console.log(res.data, "res.data");
      })

      .catch((error) => {
        setLoginToken(true);
      });

  }, [API_HANBAITEN_ORDERINFO, varToken]);

  const [messageData, setMessageData] = useState();
  const [messageDataMemo, setMessageDataMemo] = useState();
  

  return (
    <div className="App" style={{ padding: '40px'}}>
      <div>
        <Login setToken={loginToken} currentPage={CURRENT_PAGE} API={API} />
        <div className={'top-element'} style={{ marginBottom: 20, alignItems: "center", display: 'flex', justifyContent: 'space-between' }}>
          <Button
            style={{
              backgroundColor: CURRENT_PAGE === "hat" ? "#092ea9" : "#141925",
              color: "white",
              height: '40px'
            }}
            onClick={() => {
              if (order && order.id) {
                setOrder(null);
                localStorage.setItem("access_token", "");
              } else {
                localStorage.setItem("access_token", "");
                window.location.reload(true);
              }
            }}
          >
            {order && order.id ? "ログアウトする" : "ログインする"}
          </Button>
            {API.indexOf('stag') > -1 && <span style={{
              fontSize: "24px",
              color: "red",
              marginLeft: "10px",
              fontWeight: "bold" }}
            >テスト環境</span>}
            {CURRENT_PAGE === PAGE_HANBAITEN &&
              order?.status !== "pending" && (
                <div
                  style={{
                    backgroundColor:
                      CURRENT_PAGE === PAGE_HAT
                        ? "#092ea9"
                        : CURRENT_PAGE === PAGE_HANBAITEN
                        ? "#141925"
                        : "transparent",
                    color: "white",
                    padding: 9,
                  }}
                >
                  {order?.status === "approved"
                    ? "この注文は既に承認されました。"
                    : order?.status === "confirmed"
                    ? "この注文は、橋本総業より確認されました。"
                    : order?.status === "canceled"
                    ? "この注文は取り消されました。"
                    : ""}
                </div>
              )}

            {CURRENT_PAGE === PAGE_HAT && order?.status !== "approved" && (
              <div
                style={{
                  backgroundColor:
                    CURRENT_PAGE === PAGE_HAT
                      ? "#092ea9"
                      : CURRENT_PAGE === PAGE_HANBAITEN
                      ? "#141925"
                      : "transparent",
                  color: "white",
                  padding: 9,
                }}
              >
                {order?.status === "confirmed"
                  ? "この注文は、橋本総業より確認されました。"
                  : order?.status === "canceled"
                  ? "この注文は取り消されました。"
                  : order?.status === "pending"
                  ? "この注文は販売店承認待ちです。"
                  : ""}
              </div>
            )}
        </div>
        {order && (
          <Row class="Scale">
            <Col flex="1 1" style={{ marginRight: '20px'}} className={'col-left'}>
              <div style={{ maxWidth: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <InforOrder infoOrder={order} currentPage={CURRENT_PAGE} />
                <AddressShip addressShip={order} currentPage={CURRENT_PAGE} />
                <Contact contact={order} currentPage={CURRENT_PAGE} />
              </div>
            </Col>
            <Col flex="1 1">
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'space-between',
                  height: "100%",
                }}
              >
                <YourOrder yourOrder={order} currentPage={CURRENT_PAGE} />
                <Message
                  getData={(data) => setMessageData(data)}
                  getDataMemo={(data) => setMessageDataMemo(data)}
                  currentPage={CURRENT_PAGE}
                  infoOrder={order}
                />
              </div>
            </Col>
          </Row>
        )}
        {!isInserting &&
          ((order?.status === "pending" && CURRENT_PAGE === PAGE_HANBAITEN)
          || (order?.status === "approved" && CURRENT_PAGE === PAGE_HAT && order.ownerType === "hat")) 
          && (
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
              <Button
                style={{
                  backgroundColor:
                    CURRENT_PAGE === "hat" ? "#092ea9" : "#141925",
                  color: "white",
                  height: '40px'
                }}
                disabled={isInserting}
                onClick={() =>
                  Modal.confirm({
                    title:
                      CURRENT_PAGE === "hat"
                        ? "注文を確認完了にします。よろしいですか？"
                        : "注文を承認します。よろしいですか？",
                    icon: <ExclamationCircleOutlined />,
                    okText: "OK",
                    cancelText: "閉じる",
                    onOk: onConfirm,
                  })
                }
              >
                {CURRENT_PAGE === "hat" ? "確認完了にする" : "注文を承認する"}
              </Button>
              <Button
                className="button3"
                style={{
                  backgroundColor:
                    CURRENT_PAGE === "hat" ? "#092ea9" : "#141925",
                  color: "white",
                  height: '40px'
                }}
                disabled={isInserting}
                onClick={() =>
                  Modal.confirm({
                    title: "注文を取り消します。よろしいですか？",
                    icon: <ExclamationCircleOutlined />,
                    okText: "OK",
                    cancelText: "閉じる",
                    onOk: onClickCancel,
                  })
                }
              >
                注文を取り消す
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default Medital;
