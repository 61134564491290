import React, { Component } from "react";
import MyItem, {MyTitle, MyItemMultiLine} from './MyItem';

class AddressShip extends Component {
  render() {
    return (
      <div
        style={{
          boxShadow: "0 0 10px #cac7c7",
          background: "#ffffff",
          marginBottom: "20px",
          height: '220px'
        }}
        >
        <MyTitle title='お届け先' numberText={2} currentPage={this.props.currentPage}/>
        <MyItemMultiLine title='住所' content={this.props.addressShip?.deliveryAddress}/>
      </div>
    );
  }
}

export default AddressShip;
